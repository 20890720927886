<template>
	<b-card no-body>
		<b-row>
			<b-col class="col-md-12 col-lg-12">
				<vue-apex-charts type="line" height="325px" :options="chartOptions" :series="series" />
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import flockAnalysisUpdate from "@/libs/flockAnalysisUpdate";
import vmson from "@/libs/empty";

import { required, email, min_value, max_value, integer } from "@validations";
export default {
	components: {
		DeleteModal,
		VueApexCharts,
		vSelect,
	},
	created() {
		this.flockId = this.$route.params.flockId;
		this.getData(this.flockId);
	},
	mounted() {
		flockAnalysisUpdate.$on("flockIdUpdateEvent", (id) => {
			this.getData(id);
		});
	},
	data() {
		return {
			min: "",
			max: "",
			interval: [],
			series: [
				{
					name: "body weight",
					data: [],
				},
				{
					name: "standard body weight",
					data: [],
				},
			],
			chartOptions: {
				chart: {
					height: 350,
					type: "line",
					dropShadow: {
						enabled: true,
						color: "#000",
						top: 18,
						left: 7,
						blur: 10,
						opacity: 0.2,
					},
					toolbar: {
						show: false,
					},
				},
				colors: ["#FF0000", "#77B6EA"],
				dataLabels: {
					enabled: true,
				},
				stroke: {
					curve: "smooth",
				},
				title: {
					text: "Graph Analysis & Current Report",
					align: "left",
				},
				grid: {
					borderColor: "#e7e7e7",
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					categories: [],
					title: {
						text: "Weeks",
					},
				},
				yaxis: {
					min: 0,
					max: 5000,
					title: {
						text: "Ratio",
					},
				},
				legend: {
					position: "top",
					horizontalAlign: "right",
					floating: true,
					offsetY: -25,
					offsetX: -5,
				},
			},
		};
	},
	methods: {
		getData(flockId) {
			let url = "";
			if (flockId != null) {
				url = `web/get-body-weight-analysis?flockId=${flockId}`;
			} else {
				url = `web/get-body-weight-analysis`;
			}
			this.$http.get(url).then((response) => {
				let total_weight = [];
				let standard_weight = [];
				response.data.forEach((item) => {
					let total_weight_float = this.toFixed(item.total_weight, 2);
					let standard_weight_float = this.toFixed(item.standard_weight, 2);
					total_weight.push(total_weight_float);
					standard_weight.push(standard_weight_float);
				});
				let mapGraph = this.series.map((item) => {
					if (item.name === "body weight") {
						item.data = total_weight;
					} else {
						item.data = standard_weight;
					}
					return item;
				});
				this.min = Math.min(...mapGraph);
				this.max = Math.max(...mapGraph);
				this.series = [...mapGraph];
				let interval = [];
				response.data.forEach((item) => {
					interval.push(item.interval);
				});
				this.chartOptions.xaxis.categories = interval;
				flockAnalysisUpdate.$off("flockIdUpdateEvent", flockId);
			});
		},
		toFixed(num, fixed) {
			var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
			return num.toString().match(re)[0];
		},
	},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
