<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Analysis Data</h2>
					</div>
				</div>
			</div>
		</div>
		<!-- Table Container Card -->
		<b-card no-body>
			<b-tabs class="m-2">
				<b-tab title="First" active>
					<template #title>
						<!-- <b-img
              :src="require('@/assets/images/svg/candling.svg')"
             style="margin-right: 7px; width: 20px"
            /> -->
						<span>Broiler</span>
					</template>
					<BroilerList />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<!-- <b-img
              :src="require('@/assets/images/svg/vaccination-sch.svg')"
             style="margin-right: 7px; width: 20px"
            /> -->
						<span>Breeder</span>
					</template>
					<BreederList />
				</b-tab>
				<b-tab lazy>
					<template #title>
						<!-- <b-img
              :src="require('@/assets/images/svg/vaccination-sch.svg')"
             style="margin-right: 7px; width: 20px"
            /> -->
						<span>Layer</span>
					</template>
					<LayerList />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
import vSelect from "vue-select";
import BroilerList from "./Broiler/BroilerList.vue";
import LayerList from "./Layer/LayerList.vue";
import BreederList from "./Breeder/BreederList.vue";

export default {
	components: {
		vSelect,
		BroilerList,
		LayerList,
		BreederList,
	},
	data: () => ({
		selected: null,

		options: [
			{ value: null, text: "" },
			{ value: "a", text: "1" },
			{ value: "b", text: "2" },
		],
	}),
	methods: {
		clearFlockId() {
			this.$router.push({
				path: `/apps/analysis`,
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
