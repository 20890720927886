<template>
    <b-card no-body>
        <div class="">
            <!-- <b-row class="d-flex justify-content-center align-items-center">
        <b-col cols="12" md="7">
          <b-row>
            <b-col md="5 p-0 ">
              <label for="example-input">From Date</label>
              <b-form-datepicker
                id="from-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.from_date"
              />
            </b-col>
            <b-col md="5 ">
              <label for="example-input">To Date</label>
              <b-form-datepicker
                id="to-date"
                placeholder="Choose a date"
                local="en"
                v-model="search_filter.to_date"
              />
            </b-col>
            <b-col md="2 p-0 d-flex align-items-end">
              <b-button @click="getFeedingSchedule()" variant="primary ">
                Filter
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          cols="12"
          md="5"
          class="mt-2 d-flex justify-content-end align-items-center"
        >
          <div>
            <b-form-input
              type="text"
              class="form-control"
              placeholder="Search"
              @input="getFeedingSchedule()"
              v-model.trim="search_filter.input_field"
              style="border-radius: 4px"
            />
          </div>
          <b-button
            @click="clearFilter"
            variant="secondary
 			 ml-1"
          >
            Clear
          </b-button>
          <b-button v-b-modal.feeding-schedule variant="primary mr-1 ml-1">
            Add
          </b-button>
          <b-button @click="excelDownload()" variant="warning">
            <feather-icon icon="DownloadIcon" size="16" />
          </b-button>
        </b-col>
      </b-row> -->
        </div>
        <b-row>
            <b-col class="col-md-12 col-lg-12">
                <vue-apex-charts type="line" height="325px" :options="chartOptions" :series="series" />
            </b-col>
        </b-row>
    </b-card>
</template>

<script>

import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, email, min_value, max_value, integer } from "@validations";
export default {
    components: {
        DeleteModal,
        VueApexCharts,
        vSelect,
    },
    data: () => ({
        series: [
            {
                name: "Graph Analysis",
                data: [28, 29, 33, 36, 32, 32, 33]
            },
            {
                name: "Current Report",
                data: [12, 11, 14, 18, 17, 13, 13]
            }
        ],
        chartOptions: {
            chart: {
                height: 350,
                type: 'line',
                dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                }
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
                enabled: true,
            },
            stroke: {
                curve: 'smooth'
            },
            title: {
                text: 'Graph Analysis & Current Report',
                align: 'left'
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            markers: {
                size: 1
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                // title: {
                //   text: 'Month'
                // }
            },
            yaxis: {
                // title: {
                //   text: 'Temperature'
                // },
                min: 5,
                max: 40
            },
            // fields: [
            //     { key: "index", label: "No" },
            //     "Daily Feed Consumption",
            //     "Cumulative Feed",
            //     "Body Weight",
            //     { key: "Water", label: "feeding status" },
            //     {
            //         key: "FCR",
            //         label: "created at",
            //     },
            //     "Mortality",
            //     "Morbidity",
            //     "Actions",
            // ],
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: true,
                offsetY: -25,
                offsetX: -5
            }
        },


    }),
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
