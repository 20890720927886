<template>
	<b-card no-body>
		<div class="row" style="justify-content: end">
			<div class="col-6 form-label-group m-2">
				<validation-provider #default="{ errors }" name=" Select User" rules="required">
					<v-select
						v-model="formData.flockId"
						:value="$route.params.flockId"
						:options="flock"
						label="flock_id"
						clearable:false
						:reduce="(e) => e.flock_id"
						@input="setFlockId"
					/>
					<small class="text-danger">{{ errors[0] }}</small>
				</validation-provider>
			</div>
		</div>
		<b-row>
			<b-col class="col-md-12 col-lg-12">
				<b-tabs lazy vertical nav-wrapper-class="nav-vertical">
					<b-tab title="FCR">
						<b-card>
							<FCR />
						</b-card>
					</b-tab>
					<!-- <b-tab title="Water">
						<b-card>
							<Water />
						</b-card>
					</b-tab> -->
					<b-tab title="Mortality">
						<b-card>
							<Mortality />
						</b-card>
					</b-tab>
					<!-- <b-tab title="Morbidity">
						<b-card>
							<Morbidity />
						</b-card>
					</b-tab> -->
					<b-tab title="Body Weight">
						<b-card>
							<BodyWeight />
						</b-card>
					</b-tab>
					<b-tab title="Cumulative Feed">
						<b-card>
							<CumulativeFeed />
						</b-card>
					</b-tab>
					<!-- <b-tab title="Daily Feed Consumption" class="ml-2 mb-2">
						<b-card>
							<DailyFeedConsumption />
						</b-card>
					</b-tab> -->
				</b-tabs>
			</b-col>
		</b-row>
	</b-card>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, email, min_value, max_value, integer } from "@validations";
import FCR from "./FCR.vue";
import Water from "./Water.vue";
import Mortality from "./Mortality.vue";
import Morbidity from "./Morbidity.vue";
import BodyWeight from "./BodyWeight.vue";
import CumulativeFeed from "./CumulativeFeed.vue";
import DailyFeedConsumption from "./DailyFeedConsumption.vue";
import flockAnalysisUpdate from "@/libs/flockAnalysisUpdate";

export default {
	components: {
		DeleteModal,
		VueApexCharts,
		vSelect,
		FCR,
		Water,
		Mortality,
		Morbidity,
		BodyWeight,
		CumulativeFeed,
		DailyFeedConsumption,
	},
	data() {
		return {
			infoData: [],
			flock: [],
			formData: {
				flockId: null,
			},
			flockId: null,
		};
	},
	created() {
		this.clearFlockId();
		this.getAllFlockList();
	},
	methods: {
		getAllFlockList() {
			let type = 2;
			// type is production type of the breed. 1 is Broiler
			axiosIns
				.get(`web/get-all-flock-list?type=${type}`)
				.then((response) => {
					this.flock = response.data.flock_list;
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},
		setFlockId(flockId) {
			flockAnalysisUpdate.$emit("flockIdUpdateEvent", flockId);
			this.$router.push({
				path: `/apps/analysis/${flockId}`,
			});
		},
		clearFlockId() {
			this.$router.push({
				path: `/apps/analysis`,
			});
		},
	},
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
